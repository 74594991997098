exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-eight-js": () => import("./../../../src/pages/eight.js" /* webpackChunkName: "component---src-pages-eight-js" */),
  "component---src-pages-eighteen-js": () => import("./../../../src/pages/eighteen.js" /* webpackChunkName: "component---src-pages-eighteen-js" */),
  "component---src-pages-four-js": () => import("./../../../src/pages/four.js" /* webpackChunkName: "component---src-pages-four-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-twelve-js": () => import("./../../../src/pages/twelve.js" /* webpackChunkName: "component---src-pages-twelve-js" */)
}

